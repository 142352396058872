<template>
  <div id="app">
    <router-view />
    <div v-if="show" class=" fixed bottom-[100px] right-[24px] cursor-pointer min-w-[100px] min-h-[100px] rounded-lg shadow-[0_0_20px_0_rgba(0,0,0,.2)]">
      <i class="el-icon-circle-close text-[16px] text-gray-400 absolute -top-4 -right-4" @click="show = false"></i>
      <img
        class="w-[100px] h-[100px] hover:w-[200px] hover:h-[200px]"
        src="@/assets/images/home_qrcode.png"
        mode="scaleToFill"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true
    }
  },
  beforeCreate() {
    // const UA = window.navigator.userAgent.toLowerCase();
    
    // const isAndroid = (UA && UA.indexOf('android') > 0);
    // const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA));

    // console.log(isAndroid,isIOS);
    // console.log(window.location.href)
    // if(isAndroid || isIOS) {
    //   console.log('动端环境')
    //   const url = window.location.href;
    //   if(url.search('https://pad.huiyishanji.com') == -1) {
    //     window.location.href =  'https://pad.huiyishanji.com';
    //   }
    // }else {
    //   console.log('PC')
    // }
  },
}
</script>

<style>
.strong {
  font-weight: 600;
}
* {
  margin: 0;
  padding: 0;
  /* scrollbar-width: none; */
  /* -ms-overflow-style: none; */
}
*::-webkit-scrollbar {
  width: 0px; 
    height: 0px;
    background-color: #eee;
} 
#app {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}
.el-message {
  top: 100px !important;
}
.confirmbtn {
  color: #ffffff;
  background-color: #13bfba !important;
  border-color: #13bfba !important;
}
.cancelbtn:hover {
  color: #13bfba !important;
  background-color: #fff !important;
  border-color: #13bfba !important;
}
.video-js .vjs-big-play-button {
  transform: scale(0.8);
}
.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #13bfba !important;
}
.el-loading-spinner {
  left: 50% !important;
  margin-left: -25px;
}
</style>
